import { Link } from "gatsby"
import React from "react"

export default function () {
  return (
    <ul id="projects-list">
      <li>
        <Link to="/crypto/">Crypto</Link>
      </li>
    </ul>
  )
}
