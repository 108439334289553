import React from "react"

import ProjectsList from "./ProjectsList"

export default function () {
  return (
    <section id="projects">
      <h2>Projects</h2>
      <ProjectsList />
    </section>
  )
}
