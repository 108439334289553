import React from "react"

import Layout from "../components/layout"
import Projects from "../components/Projects"

export default function Home() {
  return (
    <Layout>
      <Projects />
    </Layout>
  )
}
